import { ReportsAccess } from 'Models/IReportsAccess';
import { clientApi } from 'api';

export const fetchPermissions = async ({
  token,
  clientQuery,
}: {
  token: string;
  clientQuery: string;
}) => {
  try {
    const { data } = await clientApi.post<ReportsAccess>('permissions', { token });

    return {
      navigationData: data,
      selectedClient:
        data?.reports_accesses.find((client) => client.client === clientQuery) ||
        data?.reports_accesses[0],
    };
  } catch (error) {
    throw new Error(error?.message);
  }
};
