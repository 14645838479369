import { Report } from 'Models/IReportsAccess';
import ArrowCard from 'assets/svg/ArrowCard';
import InformationIcon from 'assets/svg/InformationIcon';
import Locked from 'assets/svg/Locked';
import { useMemo, useState } from 'react';

const useListViewCard = ({
  setHoveredReport,
  report,
  hoveredReport,
  defaultReport,
}: {
  setHoveredReport: React.Dispatch<React.SetStateAction<Report>>;
  hoveredReport: Report;
  defaultReport: Report;
  report: Report;
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const rightIcon = useMemo(() => {
    if (report.disabledReport && report.companyAccess) {
      return <InformationIcon width={23} height={23} className="fill-primary mr-2" />;
    }
    if (report.disabledReport && !report?.companyAccess) {
      return <Locked width={40} height={40} className="fill-default_dark_gray" />;
    }
    if (
      (hoveredReport?.reportNavigationDisplayName ===
        defaultReport?.reportNavigationDisplayName &&
        hoveredReport?.reportNavigationDisplayName === report.reportNavigationDisplayName) ||
      hoveredReport?.reportNavigationDisplayName === report.reportNavigationDisplayName
    ) {
      return <ArrowCard width={40} height={40} />;
    }
    return null;
  }, [report, isHovered, hoveredReport, defaultReport]);

  const backgroundColor = useMemo(() => {
    if (
      (hoveredReport?.reportNavigationDisplayName ===
        defaultReport?.reportNavigationDisplayName &&
        hoveredReport?.reportNavigationDisplayName === report.reportNavigationDisplayName) ||
      hoveredReport?.reportNavigationDisplayName === report.reportNavigationDisplayName
    ) {
      return 'bg-secondary border-secondary';
    }
    return 'bg-white';
  }, [hoveredReport, defaultReport, report]);

  const handleMouseEnter = () => {
    setIsHovered(true);
    setHoveredReport(report);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return {
    rightIcon,
    handleMouseEnter,
    handleMouseLeave,
    backgroundColor,
    isHovered,
  };
};

export default useListViewCard;
