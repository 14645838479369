import { NavigationType, Report } from 'Models/IReportsAccess';
import { useReportsContext } from 'context/ReportsContext';
import { useEffect, useRef, useState } from 'react';

const usePageContainer = ({
  isMultiChannel,
  navigationSection,
}: {
  navigationSection: NavigationType;
  isMultiChannel: boolean;
}) => {
  const { selectedClient, clientData } = useReportsContext();
  const [accessReports, setAccessReports] = useState<Report[]>();
  const [hoveredReport, setHoveredReport] = useState<Report>();

  const observer = useRef(null);
  const reportRefs = useRef([]);
  // console.log('accessReports', accessReports);
  useEffect(() => {
    const reports =
      selectedClient?.accessManagment?.find((el) => el.section === navigationSection)
        ?.reports || [];

    setAccessReports(reports);
  }, [selectedClient, navigationSection, isMultiChannel]);

  useEffect(() => {
    if (accessReports?.length > 0) {
      setHoveredReport({
        ...accessReports[0],
      });
    }
  }, [accessReports]);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.remove('fadeaway');
          } else {
            entry.target.classList.add('fadeaway');
          }
        });
      },
      { threshold: 0.99 },
    );

    reportRefs.current.forEach((ref) => {
      if (ref) {
        observer.current.observe(ref);
      }
    });

    return () => {
      if (observer.current) {
        reportRefs.current.forEach((ref) => {
          if (ref) {
            observer.current.unobserve(ref);
          }
        });
      }
    };
  }, [accessReports]);

  const onOpenReport = (report: Report) => {
    if (!report.report_url) {
      setHoveredReport(report);
    } else {
      window.open(report.report_url);
    }
  };
  const onOpenBrochure = (report: Report) => {
    if (!report.downloadBrochureLink) {
      setHoveredReport(report);
    } else {
      window.open(report.downloadBrochureLink);
    }
  };

  return {
    hoveredReport,
    accessReports,
    setHoveredReport,
    onOpenReport,
    client: clientData?.data,
    reportRefs,
    onOpenBrochure,
  };
};

export default usePageContainer;
