import { ClientAccess, NavigationType } from 'Models/IReportsAccess';

export const findAccessReportsBySection = (
  selectedClient: ClientAccess,
  section: NavigationType,
) => {
  if (section === 'Custom') {
    const access = selectedClient?.accessManagment?.find((el) => el.section === section);
    return !access || access?.reports?.length === 0;
  }
  const access = selectedClient?.accessManagment?.find(
    (el) => el.section === section,
  )?.disabledSection;

  return access;
};

export const disableAccess = (selectedClient: ClientAccess, section: NavigationType) =>
  findAccessReportsBySection(selectedClient, section);
