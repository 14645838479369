import HTMLDisplay from 'components/HTMLDisplay';
import { Report } from 'Models/IReportsAccess';
import React from 'react';

interface CTAReportAccessProps {
  report: Report;
}
const CTAReportAccess: React.FC<CTAReportAccessProps> = ({ report }) => (
  <div className=" w-full">
    <HTMLDisplay html={report?.description} />
    {/* Maybe we need CTA here in the future */}
    {/* <div className="w-100 flex justify-center">
      <Button
        onClick={onOpenReport}
        main={
          <div className="flex items-center ">
            Open in PowerBI
            <ArrowTopRightOnSquareIcon className="h-6 w-6 ms-1" />
          </div>
        }
        btnType="secondary"
        textStyles="text-black"
        containerStyles="w-full"
      />
    </div> */}
  </div>
);
export default CTAReportAccess;
