import { useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { IClient } from 'Models/IClient';
import { ClientAccess, Report, ReportsAccess } from 'Models/IReportsAccess';
import { fetchAccessManagement, fetchProductsDescription } from 'api/requests/deploybot';
import { fetchPermissions } from 'api/requests/general';
import useCookies from 'hooks/useCookies';
import React, { createContext, useContext, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

interface ReportsContextType {
  navigationData: ReportsAccess;
  productsDescription: Report[];
  setProductsDescription: React.Dispatch<React.SetStateAction<Report[]>>;
  selectedClient: ClientAccess;
  setSelectedClient: React.Dispatch<React.SetStateAction<ClientAccess>>;
  setLoadingProducts: React.Dispatch<React.SetStateAction<boolean>>;
  loadingProducts: boolean;
  clientData?: UseQueryResult<IClient, unknown>;
  loading?: boolean;
}
type NavigationData = { selectedClient: ClientAccess; navigationData: ReportsAccess };
const ReportsContext = createContext<ReportsContextType | undefined>(undefined);

export const ReportsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [searchParams] = useSearchParams();

  const queryClient = useQueryClient();

  const [productsDescription, setProductsDescription] = useState<Report[]>([]);
  const [loadingProducts, setLoadingProducts] = useState(true);

  const { token } = useCookies();

  const cachedSelectedClient = queryClient.getQueryData<ClientAccess>(['selectedClient']);

  const clientData = useQuery({
    queryKey: ['access-management', cachedSelectedClient?._id],
    queryFn: () => fetchAccessManagement({ clientId: cachedSelectedClient?._id }),
    // "_" is for no access at all ( no need to fetch)
    enabled: !!cachedSelectedClient?._id && cachedSelectedClient?._id !== '_',
    refetchOnWindowFocus: false,
    retry: 1,
  });

  const { data: navigationData, isLoading: loading } = useQuery(
    ['navigationData'],
    () =>
      fetchPermissions({
        token,
        clientQuery: searchParams?.get('client'),
      }),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      onSuccess: (data: NavigationData) => {
        queryClient.setQueryData(['navigationData'], data);
        if (data.selectedClient) {
          queryClient.setQueryData(['selectedClient'], data.selectedClient);
        }
      },
    },
  );

  useQuery({
    queryKey: ['products', navigationData?.selectedClient._id],
    queryFn: () =>
      fetchProductsDescription({
        setProductsDescription,
        setLoadingProducts,
        clientId: cachedSelectedClient?._id,
      }),
    retry: 1,
    refetchOnWindowFocus: false,
  });

  const contextValue = useMemo(
    () => ({
      navigationData: navigationData?.navigationData || {
        reports_accesses: [],
        auth0_roles: [],
      },
      loading,
      productsDescription,
      setProductsDescription,
      selectedClient: cachedSelectedClient,
      setSelectedClient: (_selectedClient: ClientAccess) => {
        queryClient.setQueryData(['selectedClient'], _selectedClient);
      },
      setLoadingProducts,
      loadingProducts,
      clientData,
    }),
    [navigationData, productsDescription, cachedSelectedClient, clientData],
  );
  return <ReportsContext.Provider value={contextValue}>{children}</ReportsContext.Provider>;
};

export const useReportsContext = () => {
  const context = useContext(ReportsContext);
  if (context === undefined) {
    throw new Error('useReportsContext must be used within a ReportsProvider');
  }
  return context;
};
