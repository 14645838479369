/* eslint-disable jsx-a11y/anchor-is-valid */
import AccessManagement from 'assets/svg/AccessManagement';
import MultiChannel from 'assets/svg/MultiChannel';
import Settings from 'assets/svg/Settings';
import clsx from 'clsx';
import { showToast } from 'helpers/ToastHelper';
import Cookies from 'js-cookie';
import React from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import Arrow from '../assets/svg/Arrow';
import BespokeSolutions from '../assets/svg/BespokeSolutions';
import Catapult from '../assets/svg/Catapult';
import DigitalShelf from '../assets/svg/DigitalShelf';
import Logout from '../assets/svg/Logout';
import MediaAnalytics from '../assets/svg/MediaAnalytics';
import Modules from '../assets/svg/Modules';
import RetailAnalytics from '../assets/svg/RetailAnalytics';
import Dropdown from './Dropdown';
import ToolTip from './Tooltip';
import { useReportsContext } from 'context/ReportsContext';
import useCookies from 'hooks/useCookies';
import { checkIfSectionPage, getPrimaryEmail } from 'helpers/utils';

const SideBar = ({ children }: React.PropsWithChildren) => {
  const { pathname } = useLocation();
  const { email, token } = useCookies();

  const { clientData } = useReportsContext();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, _setSearchParams] = useSearchParams();

  const query = new URLSearchParams({ client: searchParams.get('client') });

  const navigation = [
    {
      name: 'Retail',
      href: '/portal/retail',
      icon: RetailAnalytics,
    },
    {
      name: 'Media',
      href: '/portal/media',
      icon: MediaAnalytics,
    },
    {
      name: 'Digital Shelf',
      href: '/portal/digital-shelf',
      icon: DigitalShelf,
    },
    {
      name: 'Bespoke Solutions',
      href: '/portal/bespoke-solutions',
      icon: BespokeSolutions,
    },
    {
      name: 'Multi-Channel',
      href: '/portal/multi-channel',
      icon: MultiChannel,
    },
  ];
  const userNavigation = [
    { name: 'Access Management', href: '/access-managment', icon: AccessManagement },
    // { name: 'Authentication', href: '#', icon: Authentication },
  ];

  const onSignOut = async () => {
    try {
      Cookies.remove('email');
      Cookies.remove('token');
      window.location.href = `${process.env.REACT_APP_BASE_URL}logout?token=${token}`;
    } catch (error) {
      showToast('error', error?.message);
    }
  };

  return (
    <div className="flex">
      <div className="bg-default_gray w-[56px] md:w-[72px] flex flex-col h-screen items-center justify-start fixed top-0 z-10">
        <div className="border-b-2 border-default_warm_gray px-1 md:px-[7px] py-3 md:py-[18px]">
          <div className="flex items-center justify-center w-8 h-8 md:w-9 md:h-9 bg-primary rounded-md">
            <Catapult fill="#FFFFFF" className="w-4 h-4 md:w-5 md:h-5" />
          </div>
        </div>

        <div>
          <div className="border-b-2 border-default_warm_gray py-2 md:py-3">
            <div
              className={`${
                pathname === '/portal' && 'bg-default_warm_gray'
              } flex items-center justify-center w-10 h-10 md:w-12 md:h-12 rounded-md hover:bg-default_warm_gray`}
            >
              <ToolTip tooltip="Dashboard">
                <Link to={`/portal?${query}`}>
                  <Modules
                    className={`w-10 h-10 md:w-12 md:h-12 ${
                      pathname === '/portal' ? 'fill-primary' : 'fill-default_cool_gray'
                    }`}
                  />
                </Link>
              </ToolTip>
            </div>
          </div>

          <div className="py-2 md:py-3">
            {navigation.map((item) => (
              <div
                key={item.name}
                className={`${
                  item.href === pathname && 'bg-default_warm_gray'
                } flex items-center justify-center w-10 h-10 md:w-12 md:h-12 rounded-md mb-2 md:mb-3 hover:bg-default_warm_gray`}
              >
                <ToolTip tooltip={item.name}>
                  <Link to={`${item.href}?${query}`}>
                    <item.icon
                      className={`w-10 h-10 md:w-12 md:h-12 ${
                        item.href === pathname ? 'fill-primary' : 'fill-default_cool_gray'
                      }`}
                    />
                  </Link>
                </ToolTip>
              </div>
            ))}
          </div>
          {clientData?.data?.accessManagment?.find(
            (el) => el.user.email === getPrimaryEmail(email),
          )?.isAdmin && (
            <div className="py-2 border-t-2 border-default_warm_gray">
              {userNavigation.map((item) => (
                <div
                  key={item.name}
                  className={clsx(
                    item.href === pathname
                      ? 'hover:bg-default_dark_gray bg-default_warm_gray'
                      : 'hover:bg-default_warm_gray',
                    'flex items-center justify-center w-10 h-10 md:w-12 md:h-12 rounded-md mb-1',
                  )}
                >
                  <ToolTip tooltip={item.name}>
                    <Link to={`${item.href}?${query}`}>
                      <item.icon
                        className={` ${
                          item.href === pathname
                            ? 'stroke-primary'
                            : 'stroke-default_cool_gray'
                        }`}
                      />
                    </Link>
                  </ToolTip>
                </div>
              ))}

              {process.env.NODE_ENV === 'development' && (
                <div
                  key="Components"
                  className={clsx(
                    pathname === '/components'
                      ? 'hover:bg-default_dark_gray bg-default_warm_gray'
                      : 'hover:bg-default_warm_gray',
                    'flex items-center justify-center w-10 h-10 md:w-12 md:h-12 rounded-md mb-1',
                  )}
                >
                  <ToolTip tooltip="Components">
                    <Link to="/components">
                      <Settings
                        className={` ${
                          pathname === '/components'
                            ? 'stroke-primary'
                            : 'stroke-default_cool_gray'
                        }`}
                      />
                    </Link>
                  </ToolTip>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="absolute bottom-0">
          <div className="border-t border-default_dark_gray py-2">
            <div
              className={clsx(
                pathname === '/settings'
                  ? 'hover:bg-default_dark_gray bg-default_warm_gray'
                  : 'hover:bg-default_warm_gray',
                'flex items-center justify-center w-12 h-12 rounded-md',
              )}
            >
              <ToolTip tooltip="Coming soon">
                <Link to="#" className="cursor-default">
                  <Settings
                    className={`${
                      pathname === '/settings' ? 'stroke-primary' : 'stroke-default_cool_gray'
                    }`}
                  />
                </Link>
              </ToolTip>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col flex-1 ml-[calc(6vw+56px)]  md:ml-[calc(6vw+72px)] overflow-y-auto">
        <div
          className={`bg-white flex flex-row  ${
            pathname !== '/portal' ? 'h-[13vh]' : 'justify-end '
          } w-full `}
        >
          {pathname !== '/portal' && (
            <div className="flex justify-start items-center w-[calc(50%-3vw)] mr-[1vw]">
              <Link to={`/portal?client=${query.get('client')}`}>
                <div className="flex justify-center gap-1 md:h-[72px] sm:h-[56px]  items-center">
                  <div className="hidden lg:block">
                    <Arrow
                      className="fill-default_dark_gray"
                      style={{ transform: 'rotate(180deg)' }}
                      width={32}
                      height={32}
                    />
                  </div>
                  <div className="block lg:hidden">
                    <Arrow
                      className="fill-default_dark_gray"
                      style={{ transform: 'rotate(180deg)' }}
                      width={24}
                      height={24}
                    />
                  </div>

                  <span className="text-default_dark_gray font-worksans_regular text-base md:text-lg text-left">
                    back to dashboard
                  </span>
                </div>
              </Link>
            </div>
          )}
          <div
            className={`flex justify-end w-[calc(50%+6vw)] pr-6 ${
              checkIfSectionPage(pathname) ? 'bg-light_gray' : ''
            }`}
          >
            <div className="flex md:h-[72px] 2md:h-[72px] sm:h-[56px] xs:h-[56px] items-center justify-center gap-3">
              <Dropdown />
              <div
                className="flex items-center justify-center w-8 h-8 md:w-9 md:h-9 rounded-md hover:bg-default_warm_gray cursor-pointer"
                onClick={onSignOut}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    onSignOut();
                  }
                }}
                role="button"
                aria-label="Save"
                tabIndex={0}
              >
                <Logout className="fill-default_dark_gray hover:fill-primary w-8 h-8 md:w-9 md:h-9" />
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white">{children}</div>
      </div>
    </div>
  );
};
export default SideBar;
